<template>
  <section class="bg-light p-3">
    <h2 class="text-primary">Modifier l'offre d'emploi</h2>
    <hr />
    <form class="row g-3" @submit.prevent="confirm">
      <div class="col-12">
        <label for="title-offre" class="form-label">Titre de l'offre</label>
        <input
          type="text"
          name="title-offre"
          id="title-offre"
          autocomplete="off"
          class="form-control"
          required
          v-model="form.title_offre"
        />
      </div>
      <div class="col-md-6">
        <label for="profil-offre" class="form-label">Profil</label>
        <input
          type="text"
          name="profil-offre"
          class="form-control"
          id="profil-offre"
          required
          v-model="form.profil_offre"
        />
      </div>
      <div class="col-md-6">
        <label for="lieu-offre" class="form-label">Lieu</label>
        <input
          type="text"
          name="lieu-offre"
          class="form-control"
          id="lieu-offre"
          required
          v-model="form.lieu_offre"
        />
      </div>
      <div class="col-md-6">
        <label for="type-offre" class="form-label">Type</label>
        <select
          name="type-offre"
          id="type-offre"
          class="form-control"
          required
          v-model="form.id_type_offre"
        >
          <option value="categorie-0" style="display: none"></option>
          <option
            :value="type.id_type_offre"
            v-for="type in typeOffre"
            :key="type.id_type_offre"
          >
            {{ type.name_type_offre }}
          </option>
        </select>
      </div>
      <div class="col-md-6">
        <label for="categorie-offre" class="form-label">Catégorie</label>
        <select
          name="categorie-offre"
          id="categorie-offre"
          class="form-control"
          required
          v-model="form.id_categorie_offre"
        >
          <option value="categorie-0" style="display: none"></option>
          <option
            :value="categorie.id_categorie_offre"
            v-for="categorie in categorieOffre"
            :key="categorie.id_categorie_offre"
          >
            {{ categorie.name_categorie_offre }}
          </option>
        </select>
      </div>
      <div class="col-6">
        <label for="title-offre" class="form-label">Deadline</label>
        <div>
          <date-picker
            :placeholder="form.deadline_offre"
            v-model="form.deadline_offre"
            value-type="format"
            format="YYYY-MM-DD"
          >
          </date-picker>
        </div>
      </div>
      <div class="col-12">
        <label class="form-label">Contenu principal</label>
        <div class="vueEditor">
          <vue-editor
            :customModules="customModulesForEditor"
            :editor-options="editorSettings"
            v-model="form.description_offre"
            required
          ></vue-editor>
        </div>
      </div>
      <div class="col-12 mb-2 mt-4 text-end" v-if="checkIfMine(idCollab)">
        <button v-if="loading" class="btn btn-primary" type="button" disabled>
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Envoi...
        </button>
        <button v-else type="submit" class="btn btn-primary">Modifier</button>
      </div>
    </form>
  </section>
</template>

<script>
import { VueEditor } from "vue2-editor";
import BlotFormatter from "quill-blot-formatter";
import { updateOffreById, getOffreById } from "../../api/offre";
import { success, error } from "../../utils/toast";
import { getAllTypeOffre } from "../../api/typeOffre";
import { getAllCategoryOffre } from "../../api/categorieOffre";
import { checkIfMine } from "../../utils/index";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "OffreNouveau",
  components: {
    VueEditor,
    DatePicker,
  },
  data() {
    return {
      customModulesForEditor: [
        { alias: "BlotFormatter", module: BlotFormatter },
      ],
      editorSettings: {
        modules: {
          BlotFormatter: true,
        },
      },
      form: {
        title_offre: "",
        profil_offre: "",
        description_offre: "",
        lieu_offre: "",
        id_type_offre: 0,
        id_categorie_offre: 0,
        deadline_offre: null,
      },
      loading: false,
      typeOffre: [],
      categorieOffre: [],
      idCollab: 0,
    };
  },
  mounted() {
    getOffreById(this.$route.params.id).then((result) => {
      this.loading = false;
      //console.log(result.data);
      if (result.data.error) {
        this.$router.push(`/admin/liste-offre`);
      }
      this.form = {
        title_offre: result.data.title_offre,
        profil_offre: result.data.profil_offre,
        description_offre: result.data.description_offre,
        lieu_offre: result.data.lieu_offre,
        id_type_offre: result.data.id_type_offre,
        id_categorie_offre: result.data.id_categorie_offre,
        deadline_offre: result.data.deadline_offre,
      };
      this.idCollab = result.data.id_collab;
    });
    getAllTypeOffre().then((result) => {
      this.typeOffre = result.data;
    });
    getAllCategoryOffre().then((result) => {
      this.categorieOffre = result.data;
    });
  },
  methods: {
    confirm() {
      this.loading = true;
      updateOffreById(this.$route.params.id, this.form).then((result) => {
        if (result.data.error) {
          error(result.data.error);
        } else {
          //   this.form.title_offre = "";
          //   this.form.profil_offre = "";
          //   this.form.description_offre = "";
          //   this.form.lieu_offre = "";
          //   this.form.id_type_offre = 0;
          //   this.form.id_categorie_offre = 0;
          success("Modification réussi");
          this.loading = false;
        }
      });
    },
    checkIfMine(id) {
      return checkIfMine(id);
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  min-height: 100vh;
}

.vueEditor {
  background-color: white;
}
</style>
